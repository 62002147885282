@import '../../../../../sass/defaults/variables';

// Progress bar
// ---
//
// 1. Set progress-bar width when there is an empty content
// 2. Progress value shall be on one line

.progressBar {
    position: relative;

    width: 100%; // 1
    height: 16px;

    background: $neutral-7;
    border-radius: $border-radius;

    color: $neutral-100;
    font-size: $small-font-size;
    text-align: center;
    white-space: nowrap; // 2
}


// Progress indicator
// ---
//
// 1. Needs to be block to match wrapper size
// 2. Only one color for progress

.indicator {
    position: absolute;
    display: block; // 1
    height: 100%;

    border-radius: $border-radius;
    background-color: $success-color; // 2
}

.value {
    position: relative;
}
