@import "../../../sass/defaults/variables";

.buttonWrapper {
    display: inline-block;
}

.button {
    background-color: transparent;
    transition: background-color .15s ease-in, color .15s ease-in;
    color: $medium-editor-link-color;
    font-size: 14px;
    padding: 5px 0 0 0;
    vertical-align: bottom;
    height: $medium-editor-button-size;
    width: $medium-editor-button-size;
    border: none;
}

.button svg {
    fill: $neutral-0;
}

.button:hover {
    background-color: $medium-editor-hover-color;
}

.button:focus {
    outline: 0; /* reset for :focus */
}

.active {
    background: $light-brand-color;
    color: $neutral-0;
}

.active svg {
    fill: $neutral-0;
}

.toolbar {
    display: flex;
    align-items: center;
    left: 50%;
    transform: translate(-50%) scale(0);
    position: absolute;
    border: 1px solid $medium-editor-border-color;
    background-color: $medium-editor-bgcolor;
    border-radius: $medium-editor-border-radius;
    box-shadow: none;
    z-index: 300;
    box-sizing: border-box;
}

.toolbar:after, .toolbar:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.toolbar:after {
    border-color: $medium-editor-bgcolor transparent transparent transparent;
    border-width: 8px;
    margin-left: -8px;
}

.separator {
    display: inline-block;
    border-right: 1px solid #ddd;
    height: 30px;
    margin: 0 0.5em;
}
