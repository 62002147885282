@import '../../../sass/defaults/variables.scss';

:global {
    :local(.dayPicker) {
        &.disabled {
            .DateRangePickerInput_calendarIcon {
                background-color: $disabled-input-addon-background;
                color: $disabled-input-color;
                pointer-events: none;
            }
        }

        .DayPicker {
            overflow: hidden;
        }

        .DayPicker__withBorder {
            box-shadow:
                0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }
    }

    :local(.dateInput) {
        .DateRangePickerInput {
            display: flex;
            align-items: center;
            // position: relative;
            background-color: transparent;
        }

        .DateRangePickerInput__disabled {
            background-color: $neutral-7;
        }

        .DateRangerPickerInput__withBorder {
            border-radius: 4px;
            border: 1px solid #cfd8df; // 8e9499
        }

        .DateInput {
            flex: 1;
            background-color: transparent;
        }

        .DateInput_input {
            text-align: center;
        }

        .DateInput, .DateInput__small {
            width: auto;
        }

        .DateRangerPickerInput_calendarIcon {
            color: rgba(20, 23, 26, 0.54);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 8px 0 8px 8px;
        }

        .DateRangePickerInput_arrow,
        .DateRangePickerInput_clearDates {
            display: inline-flex;
            align-items: center;
        }

        .DateRangePickerInput_clearDates {
            margin: 0;
            padding: 0;
        }

        .DateRangePickerInput_clearDates__hide {
            visibility: visible;
        }

        &.disabled {
            background-color: $neutral-7;
        }

        &.invalid {
            .DateRangerPickerInput__withBorder {
                border-color: $error-color;
            }

            .DateInput_input {
                color: inherit;
            }
        }
    }
}
