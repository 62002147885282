// Variables
//
// Edit these as needed. Some guidelines:
//
// - Names should be lowercase and dash-separated;
// - Qualifiers should be added to the beginning of related variables: use
//   `$small-font-size`, not `$font-size-small`;
// - Numeric scales should use increments of 10; these numbers are arbitrary and
//   should not map to actual values. If really necessary, additional values can
//   be added in between, e.g. $neutral-15 between 10 and 20.
//
// Styleguide Variables.default

// Layout
// ---

// Basic unit for spacing and alignment; 6 to 12px recommended. Apply in whole
// or half multiples.
$unit: 10px;


// Typography
// ---

$font-family: 'ProximaNova', sans-serif;
$header-font-family: 'ProximaNova', sans-serif;

$line-height: 21px;

$font-size: 14px;
$small-font-size: ($font-size) - 2; // 12px
$large-font-size: $font-size + 2; // 16px


// Color palette
// ---

// Neutrals
$neutral-0: #fff; // white
$neutral-2: #f0f3f6;
$neutral-7: #e5eaed;
$neutral-13: #cfd8df;
$neutral-40: #8e9499;
$neutral-100: #000; // black

// Brand colors
$brand-color: #3168aa; // blue
$light-brand-color: #4bbfeb;
$dark-brand-color: #12406f;
$xdark-brand-color: #0e3257;
$pale-brand-color: #edf8fd;
$semi-light-brand-color: #a5dff5; // 50% of a light colour

// Accent colors
$accent-color: #ee6749; // orange
$accent-secondary-color: #fdc449; // yellow

// Success colors
$success-color: #00c7ae; // green

// Error colors
$error-color: #cd1e3d; // red
$light-error-color: #f5d2d8; // pale-red


// Appearance
// ---
//
// 1. Side-bar-width should match sideNav width in sideNav
//    script options. 240px is a script default width (it fits us)

$font-color: $neutral-100;

$link-color: $brand-color;
$active-link-color: $dark-brand-color;

$text-reversed-color: $neutral-0;

$border-color: $neutral-13;
$border-radius: 2px;

$input-background-color: $neutral-0;
$input-active-color: $light-brand-color;
$input-placeholder: $neutral-40;
$disabled-input-color: $neutral-40;
$disabled-input-background-color: $neutral-7;
$disabled-input-addon-background: $neutral-13;

$horizontal-input-padding: $unit * 1.5;
$vertical-input-padding: 8px;
$form-height: 35px;

$side-nav-width: 240px; // 1


// Icon Fonts Settings
// ---
//
// These variables are used to add your icon font file to your CSS, and also to
// help generate your icon component classes.

$icon-font: 'gigwalk' !default;
$icon-font-path: '../fonts/icon-font' !default;

$icon-list: (
    angle-left:         '\e600',
    angle-right:        '\e601',
    caret-left:         '\e602',
    caret-up:           '\e603',
    caret-right:        '\e604',
    caret-down:         '\e605',
    sort:               '\e606',
    attention-circle:   '\e607',
    question-circle:    '\e608',
    cross-circle:       '\e609',

    info-circle:        '\e610',
    check-circle:       '\e611',
    check:              '\e612',
    cross:              '\e613',
    clock-update:       '\e614',
    clock:              '\e615',
    plus:               '\e616',
    minus:              '\e617',
    user:               '\e618',
    group:              '\e619',

    hamburger:          '\e620',
    envelop:            '\e621',
    image:              '\e622',
    list:               '\e623',
    calendar:           '\e624',
    settings:           '\e625',
    reload:             '\e626',
    trash:              '\e627',
    search:             '\e628',
    sign-out:           '\e629',

    location-arrow:     '\e630',
    location-point:     '\e631',
    activity:           '\e632',
    bar-chart:          '\e633',
    globe:              '\e634',
    camera:             '\e635',
    printer:            '\e636',
    edit:               '\e637',
    line-chart:         '\e638',
    organization:       '\e639',

    folder:             '\e640',
    contact:            '\e641',
    support:            '\e642',
    bug:                '\e643',
    cubes:              '\e644',
    dashboard:          '\e645',
    dollar:             '\e646',
    rocket:             '\e647',
    briefcase:          '\e648',
    target:             '\e649',

    ticket:             '\e650',
    bell:               '\e651',
    certificate:        '\e652',
    drag:               '\e653',
    refresh:            '\e654',
    copy:               '\e655',
    download:           '\e656',
    assign:             '\e657',
    unassign:           '\e658',
    info:               '\e659',

    exclamation:        '\e660',
    question:           '\e661',
    link:               '\e662',
    pencil-square:      '\e663',
    star:               '\e664',
    star-empty:         '\e665',
    coffee:             '\e666',
    home:               '\e667',
    plus-square-o:      '\e668',
    angle-up:           '\e669',

    angle-down:         '\e670',
    tasks:              '\e671',
    angle-double-left:  '\e672',
    angle-double-right: '\e673',
    paperclip:          '\e674'

) !default;


// Layer (z-index)
// ---

// Organizes z-index usage by name. Values can be incremented/decremented
// slightly as necessary. eg. $stage-layer + 1;

$backdrop-layer:  1;    // background
$stage-layer:     10;   // icon or other ui element
$orchestra-layer: 100;  // modal shade or similar
$frontrow-layer:  1000; // modal dialog or similar


// Shorthand
// ---
//
// Quick ways to write properties that you re-use often.

$border: 1px solid $border-color;
$input-border: 2px solid $border-color;
$special-border: 4px solid $neutral-2;
$box-shadow: 0 0 4px rgba($neutral-100, 0.25);
$dropdown-box-shadow: 0 3px 7px rgba($neutral-100, 0.25);
$input-padding: $vertical-input-padding $horizontal-input-padding;


// Breakpoints
// ---
//
// 1. Breakpoint when sidenav hides from viewport
//    (defined by sidenav script)

$mobile-small:          max-width 320px;
$mobile-medium-down:    max-width 420px;
$mobile-large-down:     max-width 640px;
$tablet-small-down:     max-width 736px;
$tablet-large-down:     max-width 1024px;
$tablet-large-up:       min-width 1025px;

$sidenav-display:       960px; // 1


// Medium Editor
// ---
//
// Used to define theme for Medium Editor component typography

$medium-editor-bgcolor:                  $dark-brand-color;
$medium-editor-border-color:             darken($medium-editor-bgcolor, 4%);
$medium-editor-button-size:              50px;
$medium-editor-link-color:               $neutral-0;
$medium-editor-hover-color:              $light-brand-color;
$medium-editor-border-radius:            $border-radius * 2;
$medium-editor-placeholder-color:        $medium-editor-link-color;
