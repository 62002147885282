//colors
$white: #ffffff;
$black: #000000;
$concrete: #f2f2f2;
$black_30: rgba(0,0,0,.3);
$color_regal_blue_60_approx: rgba(18,67,107,.6);
$white_90: rgba(255,255,255,.9);
$color_storm_dust_approx: #666666;
$white_70: rgba(255,255,255,.7);
$color_silver_chalice_approx: #adacac;
$color_havelock_blue_approx: #418ad4;
$color_cornflower_blue_approx: #759ae9;
$color_royal_blue_approx: #376fe0;
$color_mariner_approx: #1a5ad9;
$color_cerulean_blue_approx: #1f58cc;
$color_tory_blue_approx: #1b4db3;
$color_dodger_blue_80_approx: rgba(57, 140, 255, 0.8);
$color_mine_shaft_approx: #333;
$color_monza_approx: #d40f16;
$color_azure_approx: #2a68a7;
$color_tulip_tree_approx: #e3a53b;
$color_shady_lady_approx: #a7a7a7;

//fonts
$font_0: Helvetica Neue;
$font_1: Helvetica;
$font_2: Arial;
$font_3: sans-serif;
$font_4: sans-serif !important;

//urls
$url_0: url(http://pages.gigwalk.com/rs/858-GUP-425/images/gigwalk-hero-barista-web.jpg);
$url_2: url(http://gigwalk.s3.amazonaws.com/website/assets/enterprise/quotes-background.png);

//@extend-elements
//original selectors
//.content-wrapper, .content-wrapper-pad
%extend_1 {
    width: 100%;
    max-width: 1100px;
    margin: auto;
    padding: 0 20px;
}

//original selectors
//.grid, .inline-grid
%extend_2 {
    clear: both;
    display: block;
    float: none;
    overflow: hidden;
    width: 100%;
}

.marketing-signup {
    h1 {
        margin: 0;
        padding: 0 0 14px 0;
        font-weight: 100;
    }
    h2 {
        margin: 0;
        padding: 0;
        font-weight: 200;
    }
    h3 {
        margin: 0;
        padding: 0;
    }
    h4 {
        margin: 0;
        padding: 0;
    }
    p {
        margin: 0;
        padding: 0;
        margin-bottom: 15px;
        font-weight: 300;
    }
    ul {
        margin: 0;
        padding: 0;
    }
    ol {
        margin: 0;
        padding: 0;
    }
    span {
        margin: 0;
        padding: 0;
    }
    .center {
        text-align: center;
    }
    img {
        max-width: 100%;
    }
}
.siteWrapper {
    text-align: left;
}
.fullWrapper {
    width: 100%;
}
.contentWrapper {
    @extend %extend_1;
}
.bgWhite {
    background-color: $white;
}
.bgBlack {
    background-color: $black;
}
.bgGray {
    background-color: $concrete;
}
.grid {
    @extend %extend_2;
    .col_1_2 {
        float: left;
        padding: 0 20px;
        width: 50%;
    }
}
.inlineGrid {
    @extend %extend_2;
    z-index: 1;
    position: relative;
    .col_1_2 {
        width: 49%;
        display: inline-block;
        vertical-align: middle;
        padding: 0 20px;
    }
}
.col_1_2 {
    &:nth-of-type(odd) {
        padding-left: 0;
    }
    &:nth-of-type(even) {
        padding-right: 0;
    }
}
.copySection {
    padding: 60px 0;
    .col_1_2 {
        &:nth-of-type(odd) {
            width: 60%;
        }
        &:nth-of-type(even) {
            width: 40%;
        }
    }
    .mktoImg {
        text-align: center;
    }
}
.flexGrid {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
}
.columnWrapper {
    width: 100%;
    max-width: 70%;
    max-width: calc(100% - 340px);
}
.columnImage {
    min-width: 150px;
    margin-right: 20px;
    img {
        width: 100%;
    }
}

@media(max-width: 700px) {
    .flexGrid {
        display: block;
    }
    .columnImage {
        min-width: 100px;
        margin-right: 20px;
        width: 100px;
        float: left;
    }
    .columnWrapper {
        max-width: 100%;
    }
    .tablet {
        margin-top: 25px;
    }
}
.overlay {
    background-color: $black_30;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.overlayBlue {
    background-color: $black_30;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: $color_regal_blue_60_approx;
    position: fixed;
    display: table;
    .formWrapper {
        width: 100%;
        max-width: 500px;
        margin: auto;
        text-align: left;
    }
}
.tableCell {
    display: table-cell;
    vertical-align: middle;
}
@media(max-width: 750px) {
    .copySection {
        padding: 40px 0;
        .col_1_2 {
            display: block;
            float: none;
            padding: 0;
            width: 100%;
            &:nth-of-type(odd) {
                display: block;
                float: none;
                padding: 0;
                width: 100%;
            }
            &:nth-of-type(even) {
                display: block;
                float: none;
                padding: 0;
                width: 100%;
            }
        }
        img {
            margin: 20px 0;
        }
    }
    .hero {
        .inlineGrid {
            display: flex;
            flex-wrap: wrap;
        }
        .col_1_2 {
            &:first-of-type {
                order: 2;
            }
            &:last-of-type {
                order: 1;
            }
        }
    }
    .header {
        font-size: 40px;
        max-width: 100%;
        text-align: center;
    }
    .formWrapper {
        margin-top: 40px;
        min-height: auto !important;
    }
    .inlineGrid .col_1_2 {
        display: block;
        float: none;
        padding: 0;
        width: 100%;
    }
    .grid .col_1_2 {
        display: block;
        float: none;
        padding: 0;
        width: 100%;
    }
}

.flex_container {
    padding: 0;
    margin: 0;
    list-style: none;

    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;

    justify-content: space-between;;
}

.gigHeader {
    text-align: left;
}
.hero {
    background-image: $url_0;
    background-size: cover;
    background-repeat: no-repeat;
    color: $white;
    padding: 75px 20px;
    background-position: 50% 65%;
    position: relative;
    display: table;
    width: 100%;
}
.header {
    font-weight: 100;
    width: 100%;
    z-index: 1;
    font-size: 30px;
    max-width: 500px;
    margin: auto;
    position: relative;
    text-align: left;
    text-shadow: 0 1px 2px $black;
    padding-left: 50px;
    text-shadow: 0 0 30px #000;
    line-height: 1.15;
    @media(min-width: 600px) {
        font-size: 52px;
    }
}
@media(max-width: 400px) {
    .hero {
        padding: 80px 0;
    }
    .formWrapper h2 {
        font-size: 25px;
    }
}
.formWrapper {
    background-color: transparent;
    // padding: 20px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
        color: $color_storm_dust_approx;
        font-size: 36px;
        font-weight: 100;
        margin: 0;
        line-height: 1.2em;
    }
    p {
        color: $color_storm_dust_approx;
    }
}
.testimonial {
    text-align: center;
    background-image: $url_2;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: top center;
    padding: 60px 20px;
}
.quote {
    width: 60%;
    max-width: 600px;
    margin: auto;
}
.name {
    font-weight: 500;
    margin-top: 15px;
}
@media(max-width: 500px) {
    .testimonial {
        background-position: 50% 10%;
    }
    .quote {
        width: 80%;
    }
}

.paragraphText {
    font-family: $font_0, $font_1, $font_2, $font_4;
    font-weight: 200;
    line-height: 1.6em !important;
    h1 {
        padding: 0 !important;
        margin: 0 0 10px 0 !important;
    }
    h2 {
        padding: 0 !important;
        margin: 0 0 10px 0 !important;
    }
    h3 {
        padding: 0 !important;
        margin: 0 0 10px 0 !important;
    }
    p {
        font-size: 16px !important;
        line-height: 1.6em !important;
    }
    ul {
        list-style: initial !important;
        list-style-type: disc !important;
        list-style-position: outside !important;
        margin-left: 20px !important;
    }
    li {
        line-height: 1.6em !important;
        padding-bottom: 10px !important;
        margin: 0 !important;
    }
}
